import React from 'react'
import { BREAKPOINS } from 'common/constants/Breakpoints'
import { useWindowSize } from 'hooks/useWindow'

import { MobileNav } from './MobileNav'

import { Link } from 'gatsby'

export interface MainHeaderProps {}

export const MainHeader: React.FC<MainHeaderProps> = () => {
    const { width } = useWindowSize()

    const renderNavItem = (navTitle: string, navSlug: string) => (
        <li className="gap-r-l body fnt-utl-wei--400 fnt-utl-fam--stencil fnt-utl-trn--uppercase">
            <Link to={navSlug}>{navTitle}</Link>
        </li>
    )
    return (
        <div className="main-header">
            <div className="container">
                <div className="row gap-t-s gap-b-m">
                    <div className="col-12 col-md-6 d-flex align-items-center">
                        <Link to="/" className="main-header__logo" title={'Homepage'}>
                            <img
                                src="https://ausbildungsstellen.mainsite.de/wp-content/themes/twentytwentyone/static/logo-mainsite.png"
                                alt="mainsite-logo"
                            />
                        </Link>
                    </div>
                    {width > BREAKPOINS.md ? (
                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                            <nav className="gap-t-m gap-b-m">
                                <ul className="list list--hori">
                                    {renderNavItem(`Berufe`, '/')}
                                    {renderNavItem(`Faqs`, '/faqs')}
                                    {renderNavItem(`Die Mainsite`, '/die-mainsite')}
                                    {renderNavItem(`Kontakt`, '/kontakt')}
                                </ul>
                            </nav>
                        </div>
                    ) : (
                        <MobileNav />
                    )}
                </div>
            </div>
        </div>
    )
}

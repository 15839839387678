import React from 'react'

import { I_FooterSection, I_FooterItem } from 'common/types/Footer'
import { Headline } from 'components/Headline'
import { SocialMedia } from 'components/SocialMedia'
import { Query } from './StaticQuery'

export interface I_Footer {
    headline: string
    columns: {
        sections: I_FooterSection[]
    }[]
}

export interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
    const data: { footer: I_Footer } = Query()
    const { footer } = data

    const renderHeadline = (content: string) => (
        <Headline
            tag={'h3'}
            classes={'footer__headline h4 fnt-utl-wei--500 headline--as-label headline--as-label-m headline--clr-red'}
            paddingTop={'none'}
            paddingBottom={'xl'}
            content={content}
        />
    )

    const renderItems = (items: I_FooterItem[]) => (
        <ul className="list gap-b-xl">
            {items.map((item, index: number) => (
                <li key={index} className="list__item gap-t-s fnt-utl-clr--inverted">
                    <a className="fnt-utl-clr--inverted" href={item.key} style={{ color: 'white' }}>
                        {item.value}
                    </a>
                </li>
            ))}
        </ul>
    )

    const renderSections = (sections: I_FooterSection[]) =>
        sections.map((section: I_FooterSection, index) => (
            <div key={index}>
                <h4 className="p gap-b-s fnt-utl-clr--inverted">{section.headline}</h4>
                {renderItems(section.items)}
            </div>
        ))

    return (
        <>
            <SocialMedia />
            <footer className="footer gap-t-2xl">
                <div className="container">
                    {renderHeadline(footer.headline)}
                    <div style={{ position: 'relative', zIndex: 2 }} className="row">
                        {footer.columns.map((column, index: number) => (
                            <div key={index} className="col-12 col-md-4 col-lg-3">
                                {renderSections(column.sections)}
                            </div>
                        ))}
                    </div>
                    <span className="footer__advert ultra fnt-utl-clr--inverted">ICO</span>
                </div>
            </footer>
        </>
    )
}

import React from 'react'
import { shuffle } from 'lodash'
import Img, { FluidObject, FixedObject } from 'gatsby-image'

import { Container } from 'components/Container'
import { Headline } from 'components/Headline'
import { Image } from 'components/Image'

export interface SocialMediaProps {}

export const SocialMedia: React.FC<SocialMediaProps> = () => {
    let columns: { [key: string]: any[] } = {
        one: [],
        two: [],
        three: []
    }
    const filteredImages = [
        { src: 'images/insta-I.jpg', localeFile: { img: { fixed: '' } } },
        { src: 'images/insta-II.jpg', localeFile: { img: { fixed: '' } } },
        { src: 'images/insta-III.jpg', localeFile: { img: { fixed: '' } } },
        { src: 'images/insta-IV.jpg', localeFile: { img: { fixed: '' } } },
        { src: 'images/insta-V.jpg', localeFile: { img: { fixed: '' } } },
        { src: 'images/insta-VI.jpg', localeFile: { img: { fixed: '' } } }
    ]
    filteredImages.map((item, index) => {
        if (index < 2) {
            columns.one.push(item)
        } else if (index >= 2 && index < 4) {
            columns.two.push(item)
        } else if (index >= 4) {
            columns.three.push(item)
        }
        return columns
    })

    const renderImage = (imgFo: FixedObject, index: number) => {
        return (
            <div className="social-media__img">
                <div className="social-media__img__content">
                    {/* <Img key={index} fixed={imgFo} style={{ width: '100%', height: 'inherit' }} /> */}
                </div>
            </div>
        )
    }

    const renderHeadline = () => (
        <div className="social-media__panel">
            <Headline
                tag={'h3'}
                classes="h1 fnt-utl-trn--uppercase fnt-utl-wei--500"
                content={'Social%Media'}
                removeBr={true}
                paddingTop={'none'}
                paddingBottom={'none'}
            />
            <a href="https://www.instagram.com/future_mainsite/" target={'_blank'}>
                <Headline
                    tag={'h3'}
                    classes="text fnt-utl-wei--500 fnt-utl-trn--uppercase fnt-utl-clr--highlight-one"
                    content={'@future_mainsite'}
                    paddingTop={'m'}
                    paddingBottom={'none'}
                />
            </a>
        </div>
    )

    const renderColumn = (item: any, index: number) => {
        return (
            <div key={index} className={`social-media__column social-media__column--${item}`}>
                {item === 'one' && renderHeadline()}
                {columns[item].map((item, index) => (
                    <div key={index} className="social-media__img">
                        <div className="social-media__img__content">
                            <Image src={item.src} />
                        </div>
                    </div>
                ))}
                {/* {columns[item].map((item, index) => renderImage(item.localFile.img.fixed, index))} */}
            </div>
        )
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                <img
                    style={{ position: 'absolute', right: 0, top: 0, width: '50%', transform: 'translateY(-100%)', opacity: 0.48 }}
                    src={'/Waben schwarz_rechts_unten.png'}
                />
                <div className="social-media container-bg--subtle">
                    <Container paddingTop={'2xl'} paddingBottom={'xl'}>
                        <div className="social-media__row">{Object.keys(columns).map((item, index) => renderColumn(item, index))}</div>
                    </Container>
                </div>
            </div>
        </>
    )
}

import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'

import 'common/styles/realtime-stencil.css'
import 'common/styles/main.scss'
import { MainHeader } from 'components/MainHeader'
import { Footer } from 'components/Footer'

interface StaticQueryProps {
    site: {
        siteMetadata: {
            title: string
            description: string
            keywords: string
        }
    }
    location: Location
}

const IndexLayout: React.FC<{ children: React.ReactNode; location: Location }> = ({ children, location }) => {
    console.log(location)
    return (
        <StaticQuery
            query={graphql`
                query IndexLayoutQuery {
                    site {
                        siteMetadata {
                            title
                            description
                        }
                    }
                }
            `}
            render={(data: StaticQueryProps) => (
                <>
                    <Helmet
                        title={data.site.siteMetadata.title}
                        meta={[
                            { name: 'description', content: data.site.siteMetadata.description },
                            { name: 'keywords', content: data.site.siteMetadata.keywords }
                        ]}
                    />
                    <MainHeader />
                    <motion.div
                        key={location.pathname}
                        initial={{
                            opacity: 0
                        }}
                        animate={{ y: '0%', opacity: 1 }}
                        exit={{
                            opacity: 0,
                            transition: {
                                duration: 0.6,
                                delay: 0
                            }
                        }}
                        transition={{
                            ease: 'easeInOut',
                            duration: 0.75,
                            delay: 0.15,
                            when: 'beforeChildren'
                        }}
                    >
                        {children}
                        <Footer />
                    </motion.div>
                </>
            )}
        />
    )
}

export default IndexLayout

import React from 'react'
import { I_Component } from 'common/types/Component'

export interface ContainerProps extends I_Component {
    children: React.ReactNode
    isFluid?: boolean
}

export const Container: React.FC<ContainerProps> = ({ children, isFluid, paddingTop, paddingBottom }) => {
    return <div className={`gap-t-${paddingTop} gap-b-${paddingBottom} ${isFluid ? 'container-fluid' : 'container'}`}>{children}</div>
}

enum Breakpoints {
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    xxl = 'xxl'
}

export const BREAKPOINS: { [key in Breakpoints]: number } = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
}

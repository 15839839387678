import { useEffect } from 'react'

export function useOuterClick(onOuterClick: any, ref: any) {
    useEffect(() => {
        function handleClick(e: MouseEvent) {
            ref.current && !ref.current.contains(e.target) && onOuterClick(e)
        }

        if (ref.current) {
            document.addEventListener('click', handleClick)
            return () => document.removeEventListener('click', handleClick)
        }
    }, [onOuterClick, ref])
}

import React, { useMemo } from 'react'
import Img from 'gatsby-image'
import { queryImages } from './StaticQuery'

export interface ImageProps {
    src: string
    title?: string
    classes?: string
}

export const Image: React.FC<ImageProps> = ({ src, title, classes }) => {
    const data = queryImages()
    const match = useMemo(() => data.images.edges.find(({ node }) => src === node.relativePath), [data, src])

    if (!match) return null
    const { node } = match

    return <Img className={classes} fluid={node.childImageSharp.fluid} style={{ width: '100%', height: '100%' }} title={title} />
}

import { useStaticQuery, graphql } from 'gatsby'

export function Query() {
    return useStaticQuery(graphql`
        query Footer {
            footer: footerJson {
                headline
                columns {
                    sections {
                        headline
                        items {
                            key
                            value
                        }
                    }
                }
            }
        }
    `)
}

import { useStaticQuery, graphql } from 'gatsby'

export function queryImages() {
    return useStaticQuery(graphql`
        query {
            images: allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
                edges {
                    node {
                        relativePath
                        extension
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 1400) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)
}

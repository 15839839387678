import React from 'react'

import { I_Component, T_Padding } from 'common/types/Component'
import { Container } from 'components/Container'

export interface HeadlineProps extends I_Component {
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'p'
    content: string
    classes?: string
    removeBr?: boolean
}

export interface HeadlineSectionProps extends I_Component {
    blok: {
        content: string
        asLabel: boolean
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
    headline: HeadlineProps
}

export const Headline: React.FC<HeadlineProps> = ({ content, tag, classes, removeBr, paddingTop, paddingBottom }) => {
    const HeadlineTag = `${tag}` as keyof JSX.IntrinsicElements
    const splittedContent = content.split('%')

    return (
        <HeadlineTag className={`headline${classes ? ` ${classes}` : ''} gap-t-${paddingTop} gap-b-${paddingBottom}`}>
            {splittedContent.map((item, index) => {
                item = item.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '$1<span class="d-inline-block">$2</span>')
                return (
                    <React.Fragment key={index}>
                        <span key={index} dangerouslySetInnerHTML={{ __html: item }} />
                        {index < splittedContent.length - 1 && removeBr !== true && <br />}
                    </React.Fragment>
                )
            })}
        </HeadlineTag>
    )
}

export const HeadlineSection: React.FC<HeadlineSectionProps> = ({ blok }) => {
    if (!blok) {
        return null
    }
    const { content, paddingTop, paddingBottom, asLabel } = blok
    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <Headline
                content={content}
                tag={'h3'}
                classes={`fnt-utl-trn--uppercase h4 ${asLabel ? 'headline--as-label headline--as-label-m' : ''}`}
                paddingTop={'none'}
                paddingBottom={'none'}
            />
        </Container>
    )
}

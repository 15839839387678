import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

import { useOuterClick } from 'hooks/useOuterClick'

export interface MobileNavProps {}

export const MobileNav: React.FC<MobileNavProps> = () => {
    const refMobile = useRef<HTMLDivElement>(null)
    const [isVisible, setIsVisible] = useState<boolean>(false)

    useOuterClick(() => isVisible && setIsVisible(false), refMobile)

    const renderNavItem = (navTitle: string, navSlug: string, delay: number) => (
        <motion.li
            initial={false}
            animate={isVisible ? { x: '0%', opacity: 1 } : { x: '100%', opacity: 0 }}
            transition={{ ease: 'easeInOut', delay, duration: 0.5 }}
            className="gap-b-xl gap-r-l h3 fnt-utl-wei--400 fnt-utl-fam--stencil fnt-utl-trn--uppercase"
        >
            <Link to={navSlug} onClick={() => setIsVisible(false)}>
                {navTitle}
            </Link>
        </motion.li>
    )

    return (
        <>
            <div className="mobile-hamburger" onClick={() => setIsVisible(!isVisible)}>
                <motion.span
                    initial={false}
                    animate={isVisible ? { y: 15, rotate: '45deg' } : { y: 0, rotate: '0deg' }}
                    transition={{ ease: 'easeInOut' }}
                    className="mobile-hamburger__line"
                ></motion.span>
                <motion.span
                    initial={false}
                    animate={isVisible ? { y: 0, opacity: 0, scale: 0.25 } : { y: 0, opacity: 1, scale: 1 }}
                    transition={{ ease: 'easeInOut' }}
                    className="mobile-hamburger__line"
                ></motion.span>
                <motion.span
                    initial={false}
                    animate={isVisible ? { y: -8, rotate: '-45deg' } : { y: 0, rotate: '0deg' }}
                    transition={{ ease: 'easeInOut' }}
                    className="mobile-hamburger__line"
                ></motion.span>
            </div>
            <motion.div
                ref={refMobile}
                className="mobile-nav"
                initial={false}
                animate={isVisible ? { x: '0%' } : { x: '100%' }}
                transition={{ ease: 'easeInOut' }}
            >
                <motion.div
                    className="mobile-nav__inner"
                    initial={false}
                    animate={isVisible ? { x: '0%' } : { x: '100%' }}
                    transition={{ ease: 'easeInOut', delay: 0.15 }}
                >
                    <nav className="gap-t-m gap-b-m">
                        <ul className="list">
                            {renderNavItem(`Berufe`, '/#berufe', 0.2)}
                            {renderNavItem(`Die Mainsite`, '/die-mainsite', 0.25)}
                            {renderNavItem(`Kontakt`, '/kontakt', 0.3)}
                        </ul>
                    </nav>
                </motion.div>
            </motion.div>
        </>
    )
}

export default MobileNav
